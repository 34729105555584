import classNames from 'classnames'
import parse from 'html-react-parser'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { useApplicationsActions } from '@features/applications/reducers/ApplicationsReducer'
import Chip from '@features/jobs/components/chip/Chip'
import JobCardQuota from '@features/jobs/components/job-card-quota/JobCardQuota'
import { userKycInReview, userKycRejected } from '@features/kyc/helpers'
import {
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import Button from '@components/button'
import Drawer from '@components/drawer/Drawer'
import Switch from '@components/form/switch/Switch'
import Caption from '@components/typography/caption'
import H1 from '@components/typography/heading-one'
import H3 from '@components/typography/heading-three'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { EMPLOYMENT_TYPE_OPTIONS } from '@constants/employment-type'
import {
  BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN,
  JOB_SEARCH_CLICK_CARI_KANDIDAT,
} from '@constants/event-tracking'
import { GENDER_OPTIONS } from '@constants/genders'
import { KYC_STATUS } from '@constants/kyc-status'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import {
  EMPLOYERS_PROFILE_URL,
  GET_DEGREES_URL,
  JOB_DETAIL_URL,
} from '@constants/url'

import { formatDateInID } from '@utils/date-calculation'
import trackEvents from '@utils/track-events'

import StaticLocationFeatureToggle from '@common/hoc/StaticLocationFeatureToggle'
import BoostIcon from '@common/icons/BoostIcon'
import CariIcon from '@common/icons/CariIcon'
import CheckRoundedIcon from '@common/icons/CheckRoundedIcon'
import CloseIcon from '@common/icons/CloseIcon'

import { JobPost } from '@custom-types/job-post'

import { jobActions } from '../../reducers'
import { JobDetailProps } from '../../types'
import ExpiredDateBanner from '../expired-date-banner'
import JobStatusBadge from '../job-status-badge'
import type { JobDetailHeaderProps, JobDetailInfoProps, Props } from './type'

const JobDetailHeader: React.FC<JobDetailHeaderProps> = ({
  title,
  imageSrc,
}) => (
  <div className="flex items-center">
    <Image
      src={imageSrc}
      height={24}
      width={24}
      alt="img"
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
    <div className="ml-2">
      <H3>{title}</H3>
    </div>
  </div>
)

const JobDetailInfo: React.FC<JobDetailInfoProps> = ({
  title,
  content,
  children,
}: JobDetailInfoProps) => (
  <div className="my-3 flex flex-1">
    <div className="flex-1">
      <Caption>{title}</Caption>
    </div>
    <div className="flex flex-1 space-x-4">
      {content && <p className="line-clamp-2 flex-wrap">{content}</p>}
      {children}
    </div>
  </div>
)

const MAX_TAGS = 10
const MIN_TAGS = 5

const Slider = ({ id, isOpen, onClose, changeJobStatus }: Props) => {
  const router = useRouter()
  const { jid } = router.query
  const jobId = jid || id
  const dispatch = useAppDispatch()
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [showedTag, setShowedTag] = useState<number>(5)
  const userType = localStorage.getItem('userType')
  const isPs = userType === 'ps'
  const { data: jobDetailData } = useSWR<JobDetailProps>(
    jobId && typeof jobId === 'string' ? JOB_DETAIL_URL(jobId) : null
  )

  const { setJobApplicantsPopupFilters } = useApplicationsActions()
  const profile = useAppSelector((state) => state.profileReducer)

  const { data: degreesData } =
    useSWRImmutable<{ id: string; degreeName: string }[]>(GET_DEGREES_URL)

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const degreeName = degreesData?.filter(
    (item) => item.id === jobDetailData?.minimumEducationDegreeId
  )[0]?.degreeName

  const handleShowButton = () => {
    if (!showDetail) {
      setShowedTag(MAX_TAGS)
    } else {
      setShowedTag(MIN_TAGS)
    }

    setShowDetail((prevState) => !prevState)
  }

  const handleClickCari = () => {
    if (jobDetailData?.status !== 'Active') {
      dispatch(
        appActions.setModalName(MODAL_NAME.CANDIDATE_SEARCH_NOTICE_MODAL)
      )
    } else {
      trackEvents(JOB_SEARCH_CLICK_CARI_KANDIDAT, {
        entry_point: 'Loker',
      })

      setJobApplicantsPopupFilters({
        genders:
          jobDetailData?.requiredGender !== 'Both' &&
          jobDetailData?.requiredGender != null
            ? jobDetailData?.requiredGender
            : undefined,
        homeAddresses: jobDetailData?.location?.shortAddress
          ? [
              {
                cityName:
                  (jobDetailData?.location?.shortAddress as string) ??
                  undefined,
              },
            ]
          : undefined,
        minAge: jobDetailData?.minimumAge
          ? Number(jobDetailData?.minimumAge)
          : undefined,
        maxAge: jobDetailData?.maximumAge
          ? Number(jobDetailData?.maximumAge)
          : undefined,
        minSalary: jobDetailData?.minimumSalary ?? undefined,
        maxSalary: jobDetailData?.maximumSalary ?? undefined,
      })

      router.push({
        pathname: PATHS.SEARCH_CANDIDATE,
        query: {
          jid: id,
        },
      })
    }
  }

  const handleClickBoost = () => {
    if (jobDetailData?.status !== 'Active') {
      dispatch(appActions.setModalName(MODAL_NAME.BOOST_NOTICE_MODAL))
      return
    }

    if (
      userKycInReview({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
    } else if (
      userKycRejected({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL))
    } else {
      trackEvents(BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN)
      router.push({
        pathname: '/boost',
        query: {
          jid: id,
        },
      })
    }
  }

  const renderTag = () => {
    if (!jobDetailData) return null
    return (
      <div className="flex w-full flex-wrap">
        {jobDetailData?.attributeTags.slice(0, showedTag).map((data) => (
          <div
            key={data?.tagName}
            className="mb-2 mr-2 inline-block rounded-3xl border border-[#A792D8] bg-[#F1EBFF] px-2 text-sm text-[#7B63B4]"
          >
            {data.tagName}
          </div>
        ))}
        {jobDetailData?.attributeTags.length > MIN_TAGS && (
          <button
            type="button"
            onClick={handleShowButton}
            className="cursor-pointer text-sm text-[#4460AC] underline"
          >
            {showDetail ? 'Lihat lebih sedikit' : 'Lihat selengkapnya'}
          </button>
        )}
      </div>
    )
  }

  const renderJobStatus = () => (
    <div className="mb-6 mt-4 flex w-full items-center justify-between">
      <Caption>Status</Caption>
      <div className="flex w-1/2 justify-start">
        <JobStatusBadge jobStatus={jobDetailData?.status || ''} />
        {jobDetailData?.status === 'Active' ||
        jobDetailData?.status === 'InActive' ? (
          <div className="ml-4">
            <Switch
              onChange={() => {
                if (
                  profile.kycStatus !== KYC_STATUS.APPROVED ||
                  profile.company?.kycStatus !== KYC_STATUS.APPROVED
                ) {
                  dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
                  return true
                }

                if (jobDetailData?.status === 'Active') {
                  dispatch(appActions.setModalName(MODAL_NAME.QUESTION_MODAL))
                  dispatch(jobActions.setModalId(jobDetailData.id))
                } else {
                  changeJobStatus(
                    jobId ? (jobId as string) : (id as string),
                    jobDetailData.status
                  )
                }
                return true
              }}
              value={jobDetailData?.status === 'Active'}
            />
          </div>
        ) : null}
      </div>
    </div>
  )

  return (
    <Drawer
      articleClassName="!p-0 !space-y-0"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      hideTitle
      sectionClassName="!max-w-[680px]"
    >
      <div className="p-4">
        <button
          className="ml-auto float-right mb-[32px]"
          type="button"
          onClick={() => {
            onClose()
          }}
        >
          <CloseIcon className="text-primary-gray" width={20} height={20} />
        </button>
        <div className="clear-right">
          <div className="flex justify-between border-b pb-3">
            <H1 className="max-">{jobDetailData?.title}</H1>
            {!isPs && (
              <div className="w-[120px]">
                <Button
                  appearance="outline-primary"
                  type="button"
                  className="h-[44px] w-[120px]"
                  onClick={() => {
                    onClose()
                    router.push({
                      pathname: PATHS.EDIT_JOB,
                      query: {
                        jlid: jobDetailData?.id,
                      },
                    })
                  }}
                >
                  Edit Loker
                </Button>
              </div>
            )}
          </div>
          <div className="border-b border-gray-100 pb-4">
            {!isPs && (
              <div className="mb-6 mt-4 flex w-full items-center justify-between">
                <Caption>Paket</Caption>
                <div className="flex w-1/2 justify-start">
                  <Chip type={jobDetailData?.productPackage?.productName} />
                  {jobDetailData?.isFree && (
                    <Chip type="Free" className="ml-2" />
                  )}
                </div>
              </div>
            )}
            {!isPs && renderJobStatus()}
            <ExpiredDateBanner jobExpiredDate={jobDetailData?.expiryAt || ''} />
            {jobDetailData?.postedAt && (
              <JobDetailInfo
                title="Waktu posting"
                content={
                  jobDetailData
                    ? formatDateInID(
                        jobDetailData.postedAt,
                        'dd MMMM yyyy, HH.mm'
                      )
                    : '-'
                }
              />
            )}
          </div>
          {!isPs && (
            <JobCardQuota
              totalQuota={
                jobDetailData?.productPackage?.passiveApplicantQuota as number
              }
              quotaUsed={jobDetailData?.metric?.passiveApplicantCount}
              jlType={
                jobDetailData?.productPackage
                  ?.productName as JobPost['productPackage']['productName']
              }
              jobId={jobId && typeof jobId === 'string' ? String(jobId) : ''}
              status={jobDetailData?.status as string}
            />
          )}
          <div className="border-b border-gray-100 py-4">
            <JobDetailHeader
              title="Informasi utama"
              imageSrc="/assets/icons/ic_basic_information.svg"
            />
            <JobDetailInfo
              title="Tipe pekerjaan"
              content={jobDetailData?.jobProfiles?.[0]?.jobProfileName}
            />
            <JobDetailInfo
              title="Kebutuhan karyawan"
              content={`${jobDetailData?.openings} karyawan`}
            />
            <JobDetailInfo
              title="Lokasi pekerjaan"
              content={jobDetailData?.location?.locationAddress || '-'}
            />
            <JobDetailInfo
              title="Jenis pekerjaan "
              content={
                EMPLOYMENT_TYPE_OPTIONS.find(
                  (opt) => opt.value === jobDetailData?.employmentType
                )?.label
              }
            >
              {jobDetailData?.estimatedJobDurationInDays !== 0 &&
              jobDetailData?.estimatedJobDurationInDays ? (
                <Caption>
                  ({jobDetailData?.estimatedJobDurationInDays} hari)
                </Caption>
              ) : null}
            </JobDetailInfo>
            <JobDetailInfo title="Gaji (per bulan)">
              <div className="flex flex-wrap space-x-1">
                <NumberFormat
                  fixedDecimalScale={false}
                  prefix="Rp. "
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={0}
                  displayType="text"
                  value={jobDetailData?.minimumSalary}
                  className="border-0 px-0 py-0"
                />
                <p className="px-1">-</p>
                <NumberFormat
                  fixedDecimalScale={false}
                  prefix="Rp."
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={0}
                  displayType="text"
                  value={jobDetailData?.maximumSalary}
                  className="border-0 px-0 py-0"
                />
              </div>
            </JobDetailInfo>
            <JobDetailInfo title="Deskripsi pekerjaan">
              <div>{parse(jobDetailData?.description || '-')}</div>
            </JobDetailInfo>
          </div>
          <div className="py-4 border-b border-gray-100">
            <JobDetailHeader
              title="Persyaratan"
              imageSrc="/assets/icons/ic_requirements.svg"
            />
            <JobDetailInfo title="Keahlian yang dibutuhkan">
              {jobDetailData?.attributeTags.length !== 0 && renderTag()}
            </JobDetailInfo>

            <JobDetailInfo title="Pendidikan terakhir" content={degreeName} />
            <JobDetailInfo
              title="Pengalaman kerja"
              content={`${
                jobDetailData?.requiredExperienceInYears !== 0
                  ? jobDetailData?.requiredExperienceInYears
                  : '<1'
              } tahun`}
            />

            <JobDetailInfo title="Usia">
              {jobDetailData?.minimumAge ? (
                <div className="flex">
                  <p>{jobDetailData?.minimumAge} tahun</p>
                  {jobDetailData?.maximumAge && (
                    <>
                      <p className="px-1">-</p>
                      <p>{jobDetailData?.maximumAge} tahun</p>
                    </>
                  )}
                </div>
              ) : (
                <p>-</p>
              )}
            </JobDetailInfo>
            <JobDetailInfo
              title="Jenis kelamin"
              content={
                GENDER_OPTIONS.find(
                  (opt) => opt.value === jobDetailData?.requiredGender
                )?.label
              }
            />
            <JobDetailInfo
              title="Dokumen untuk asesmen lanjutan"
              content={`${jobDetailData?.isCVRequired ? 'CV' : '-'}`}
            />
          </div>

          <StaticLocationFeatureToggle>
            <div className="border-b border-gray-100 py-4">
              <JobDetailHeader
                title="Lokasi"
                imageSrc="/assets/icons/ic_basic_information.svg"
              />

              <JobDetailInfo title="Lokasi penempatan">
                <div className="flex flex-wrap gap-2 text-sm">
                  {jobDetailData?.placementLocations.map((item) => (
                    <div
                      key={item.id}
                      className=" flex items-center rounded-2xl border-2 border-[#A792D8] bg-[#F1EBFF] px-4 py-1 text-[#7B63B4]"
                    >
                      {item.address}
                    </div>
                  ))}
                </div>
              </JobDetailInfo>
              <JobDetailInfo title="Lokasi kandidat yang diutamakan">
                <div className="flex flex-wrap gap-2 text-sm">
                  {jobDetailData?.hiringLocations &&
                  jobDetailData.hiringLocations.length > 0 ? (
                    jobDetailData.hiringLocations.map((item) => (
                      <div
                        key={item.id}
                        className=" flex items-center rounded-2xl border-2 border-[#A792D8] bg-[#F1EBFF] px-4 py-1 text-[#7B63B4]"
                      >
                        {item.address}
                      </div>
                    ))
                  ) : (
                    <p>Tidak ada preferensi</p>
                  )}
                </div>
              </JobDetailInfo>
            </div>
          </StaticLocationFeatureToggle>
          <div className="border-b border-gray-100 py-4">
            <JobDetailHeader
              title="Benefit"
              imageSrc="/assets/icons/ic_basic_information.svg"
            />

            <JobDetailInfo title="Benefit untuk kandidat">
              <div className="flex flex-wrap items-center text-center">
                {jobDetailData?.benefitTags?.map((item) => (
                  <div
                    key={item.tagName}
                    className="my-1 mr-1 flex rounded-md bg-secondary-green px-2 py-1"
                  >
                    <CheckRoundedIcon className="mr-1" />
                    {item.tagName}
                  </div>
                ))}
              </div>
            </JobDetailInfo>
          </div>
          <div className="border-b border-gray-100 py-4 ">
            <JobDetailHeader
              title="Media untuk ditampilkan di loker (video atau gambar)"
              imageSrc="/assets/icons/ic_album_add.svg"
            />
            <div className="flex">
              {jobDetailData &&
                jobDetailData.medias.length > 0 &&
                jobDetailData.medias.map((data) => {
                  const mediaType = data?.mimeType.split('/')
                  switch (mediaType[0]) {
                    case 'video':
                      return (
                        <Link
                          href={data?.linkURL}
                          passHref
                          key={data?.link}
                          legacyBehavior
                        >
                          <a
                            target="_black"
                            className="cursor-pointer px-2 py-2"
                          >
                            <video className="h-[80px] w-[80px]">
                              <source src={data?.linkURL} />
                              <track kind="captions" />
                            </video>
                          </a>
                        </Link>
                      )
                    case 'image':
                      return (
                        <Link
                          href={data?.linkURL}
                          key={data?.link}
                          passHref
                          legacyBehavior
                        >
                          <a target="_blank" className="px-2 py-2">
                            <Image
                              src={data?.linkURL}
                              alt="job media"
                              height={80}
                              width={80}
                              style={{
                                maxWidth: '100%',
                                height: 'auto',
                              }}
                            />
                          </a>
                        </Link>
                      )
                    default:
                      return ''
                  }
                })}
            </div>
          </div>
        </div>
      </div>
      {!isPs && (
        <div className="sticky bottom-[0] border-t-[1px] bg-neutral-gray-25">
          <div className="flex items-center">
            <button
              type="button"
              className={classNames(
                'items-center',
                'justify-center',
                'text-primary-blue',
                'w-full',
                'border-[1px]',
                'border-neutral-gray-100',
                'flex',
                'items-center',
                'bg-neutral-gray-25',
                'hover:bg-neutral-gray-50',
                'gap-[8px]',
                'px-[4px]',
                'py-[16px]'
              )}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleClickCari()
              }}
            >
              <CariIcon fill="#4460AC" />
              <span>Cari</span>
            </button>

            <button
              type="button"
              className={classNames(
                'items-center',
                'justify-center',
                'text-primary-blue',
                'w-full',
                'border-[1px]',
                'border-neutral-gray-100',
                'flex',
                'items-center',
                'bg-neutral-gray-25',
                'gap-[8px]',
                'px-[4px]',
                'py-[16px]',
                jobDetailData?.isBoostable && 'hover:bg-neutral-gray-50',
                !jobDetailData?.isBoostable && 'text-secondary-gray'
              )}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleClickBoost()
              }}
              disabled={!jobDetailData?.isBoostable}
            >
              <BoostIcon stroke="#4460AC" />
              <span>Boost</span>
            </button>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default Slider
