import classNames from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import useSWRImmutable from 'swr/immutable'

import { useApplicationsActions } from '@features/applications/reducers/ApplicationsReducer'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { JOB_SEARCH_CLICK_UPGRADE } from '@constants/event-tracking'
import { PATHS } from '@constants/paths'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

import ArrowRightBlueIcon from '@common/icons/ArrowRightBlueIcon'

import { useUpgradeJobActions } from '@store/jobs/upgrade/slice'

import { JobPost } from '@custom-types/job-post'

type Props = {
  quotaUsed?: number
  jlType: JobPost['productPackage']['productName']
  totalQuota: number
  jobId: string
  status: string
}

const JobCardQuota = (props: Props) => {
  const { quotaUsed = 0, jlType = 'Basic', totalQuota, jobId, status } = props
  const quotaUsedPercentage =
    quotaUsed <= 50 ? (Number(quotaUsed) / totalQuota) * 100 : 100
  const isQuotaFull = quotaUsedPercentage === 100
  const isBasicOrFree = jlType === 'Basic'
  const isPremium = jlType === 'Premium'
  const isPremiumPlus = jlType === 'Premium+'
  const router = useRouter()
  const applicationsActions = useApplicationsActions()
  const isExpired = status === 'Expired'
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    // Reset filter
    applicationsActions.setSelectJobId('')
    router.push({
      pathname: PATHS.APPLICATIONS,
      query: {
        jid: jobId,
      },
    })
  }

  const upgradeJobActions = useUpgradeJobActions()

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)

  return (
    <button
      onClick={onClick}
      type="button"
      className={classNames(
        'w-full',
        'border',
        'border-gray-300',
        'rounded-md',
        'hover:border-primary-blue'
      )}
    >
      <div className="p-[12px]">
        <div className="flex justify-between items-center ">
          <div>
            <p className="font-semibold text-primary-black text-left text-sm">
              Total lamaran masuk
            </p>
            <div className="flex items-center mt-1">
              {isQuotaFull && isBasicOrFree && (
                <p className="text-[#FF3B30] text-xs">
                  Kuota lamaran habis. Loker tidak aktif
                </p>
              )}
            </div>
          </div>

          <button
            aria-label="Next"
            type="button"
            className="flex items-center justify-center rounded-full bg-secondary-green p-1.5 hover:bg-secondary-green-dark"
          >
            <ArrowRightBlueIcon />
          </button>
        </div>
        {isBasicOrFree && (
          <>
            <div className="w-full bg-gray-200 rounded-full h-1.5 mt-2 dark:bg-gray-700">
              <div
                className={`${isQuotaFull ? 'bg-primary-red' : 'bg-hired-1'} h-1.5 rounded-full`}
                style={{ width: `${quotaUsedPercentage}%` }}
              />
            </div>
            <div className="flex justify-between mt-2">
              <div className="flex text-primary-gray">
                <p className="text-sm">Kuota terpakai:</p>{' '}
                <strong className="text-sm ml-1">
                  {Number(quotaUsed || 0)}/{totalQuota}
                </strong>
              </div>
            </div>
          </>
        )}
      </div>

      {(isPremium || isPremiumPlus) && (
        <>
          <div className="flex justify-between px-[12px] pb-[12px]">
            <strong className="text-sm text-primary-gray">{quotaUsed}</strong>
          </div>
          {isPremium && !isExpired && (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                upgradeJobActions.setUpgradeJobOrigin('(Loker Page)')

                trackEvents(JOB_SEARCH_CLICK_UPGRADE, {
                  category: employerProfileData?.isPhantom
                    ? 'MS'
                    : 'JobProvider',
                  employerId: employerProfileData?.id,
                  fromPackage: jlType,
                  source: 'Upgrade',
                })

                router.push({
                  pathname: PATHS.UPGRADE_JOB,
                  query: {
                    jid: jobId,
                  },
                })
              }}
              className="bg-selected-for-interview-1 w-full mt-4 flex items-center justify-between rounded-b-md hover:bg-[#007B92]"
            >
              <p className="text-white px-3 py-2 font-semibold">
                Upgrade ke Premium+
              </p>
              <div className="pr-3">
                {' '}
                <Image
                  alt="ic_arrow"
                  src="/assets/icons/ic_arrow_right.svg"
                  width={16}
                  height={16}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            </button>
          )}
        </>
      )}
      {isBasicOrFree && !isExpired && (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            upgradeJobActions.setUpgradeJobOrigin('(Loker Page)')

            trackEvents(JOB_SEARCH_CLICK_UPGRADE, {
              category: employerProfileData?.isPhantom ? 'MS' : 'JobProvider',
              employerId: employerProfileData?.id,
              fromPackage: jlType,
              source: 'Upgrade',
            })

            router.push({
              pathname: PATHS.UPGRADE_JOB,
              query: {
                jid: jobId,
              },
            })
          }}
          className="bg-sunshine-400 w-full flex items-center justify-between rounded-b-md hover:bg-[#DD9427]"
        >
          <p className="text-white px-3 py-2 font-semibold">Tambah Kuota</p>
          <div className="pr-3">
            {' '}
            <Image
              alt="ic_arrow"
              src="/assets/icons/ic_arrow_right.svg"
              width={16}
              height={16}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </div>
        </button>
      )}
    </button>
  )
}

export default JobCardQuota
